// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-callback-js": () => import("/opt/build/repo/src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-members-js": () => import("/opt/build/repo/src/pages/members.js" /* webpackChunkName: "component---src-pages-members-js" */),
  "component---src-pages-svc-802-wan-nyan-accounts-js": () => import("/opt/build/repo/src/pages/svc-802-wan-nyan-accounts.js" /* webpackChunkName: "component---src-pages-svc-802-wan-nyan-accounts-js" */)
}

