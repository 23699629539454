// src/utils/auth.js

//import auth0 from "auth0-js"
import createAuth0Client from "@auth0/auth0-spa-js"
import { navigate } from "gatsby"
import { Mutex } from "await-semaphore"

const isBrowser = typeof window !== "undefined"

// const auth = isBrowser
// // ? new auth0.WebAuth({
// //   domain: process.env.AUTH0_DOMAIN,
// //   clientID: process.env.AUTH0_CLIENTID,
// //   redirectUri: process.env.AUTH0_CALLBACK,
// //   responseType: "token id_token",
// //   scope: "openid profile email",
// // })
// ?  (async () => {
//   return await createAuth0Client({
//     domain: process.env.AUTH0_DOMAIN,
//     clientID: process.env.AUTH0_CLIENTID,
//     redirectUri: process.env.AUTH0_CALLBACK,
//     responseType: "token id_token",
//     scope: "openid profile email",
//   })
// })()
// : {}
//let auth = {}
// async function buildAuth0Client(){
//   console.log("in:buildAuth0Client.")

//   var cl = await createAuth0Client({
//     domain: process.env.AUTH0_DOMAIN,
//     client_id: process.env.AUTH0_CLIENTID,
//     redirect_uri: process.env.AUTH0_CALLBACK,
//   //  response_type: "token id_token",
//     scope: "openid profile email",
//   })
//   auth = cl

//   console.log("out:buildAuth0Client.")
//   console.log("created auth:"+JSON.stringify(auth))
//   return cl
// }

// (async ()=> { await buildAuth0Client()})();
// console.log("created auth:"+JSON.stringify(auth))

let authClient = null;

export const getClient = async () => {
  console.log("in getClient.")
  if(authClient !== null){
    return authClient;
  }
  if(!isBrowser) {
    authClient = {}
  }else{
    authClient = await createAuth0Client({
      domain: process.env.AUTH0_DOMAIN,
      client_id: process.env.AUTH0_CLIENTID,
      redirect_uri: process.env.AUTH0_CALLBACK,
//      audience: "https://johaq.auth0.com/api/v2/",
      audience: "https://apis.johaq.com/auth/test/01",
    //  response_type: "token id_token",
      scope: "openid profile email",
    })
  }
  return authClient;
}

// export const getClient = async () =>{
//   console.log("in getClient.")
//   const mutex = new Mutex()
//   await mutex.use(async ()=>{

//     console.log("in getClient(aquireLock).")
//     if(authClient !== null){
//       console.log("out getClient(cached):"+JSON.stringify(authClient))
//       return authClient;
//     }
//     if(!isBrowser) {
//       authClient = {}
//     }else{
//       authClient = await createAuth0Client({
//         domain: process.env.AUTH0_DOMAIN,
//         client_id: process.env.AUTH0_CLIENTID,
//         redirect_uri: process.env.AUTH0_CALLBACK,
//       //  response_type: "token id_token",
//         scope: "openid profile email",
//       })
//     }
//     console.log("out getClient(releaseLock):"+JSON.stringify(authClient))
//     return authClient;
//   });
//   console.log("out getClient:"+JSON.stringify(authClient))

// }

// var auth = {}
// if(isBrowser){
//   createAuth0Client({
//     domain: process.env.AUTH0_DOMAIN,
//     client_id: process.env.AUTH0_CLIENTID,
//     redirect_uri: process.env.AUTH0_CALLBACK,
//   //  response_type: "token id_token",
//     scope: "openid profile email",
//   }).then((client)=>{
  
//     auth = client;
//     console.log("created auth:"+auth)
  
//   })
// }



const tokens = {
  accessToken: false,
  idToken: false,
  expiresAt: false,
}

let user = {}

export const isAuthenticated = () => {
  if (!isBrowser) {
    return;
  }
  // const auth = await getClient();

  // return await auth.isAuthenticated();
  return localStorage.getItem("isLoggedIn") === "true"
}

//export const login = () => {
//   if (!isBrowser) {
//     return
//   }

//   auth.authorize()
// }

export const login = async () => {
    if (!isBrowser) {
    return
  }

  // auth.authorize()
  console.log("in: login")
  const cl = await getClient()
  await cl.loginWithRedirect()
  localStorage.setItem("isLoggedIn", true)
}

const setSession = (cb = () => {}) => (err, authResult) => {
  if (err) {
    navigate("/")
    cb()
    return
  }


  console.log('Auth-result:'+JSON.stringify(authResult));

  if (authResult && authResult.accessToken && authResult.idToken) {
    let expiresAt = authResult.expiresIn * 1000 + new Date().getTime()
    tokens.accessToken = authResult.accessToken
    tokens.idToken = authResult.idToken
    tokens.expiresAt = expiresAt
    user = authResult.idTokenPayload
    localStorage.setItem("isLoggedIn", true)

    console.log("ACCESS-TOKEN:"+tokens.accessToken)
    navigate("/members")
    cb()
  }
}

// export const silentAuth = callback => {
//   if (!isAuthenticated()) return callback()
//   auth.checkSession({}, setSession(callback))
// }

export const silentAuth = async (callback) => {
  console.log("in silentAuth:");
  const auth = await getClient();

  if (!isAuthenticated()) return callback()
//  auth.checkSession({}, setSession(callback))

  console.log(">> silentAuth: call auth0.getTokenSilently()"+JSON.stringify(auth));
  await auth.getTokenSilently()
  .then((token)=>{
    console.log(" getTokenSilently: "+JSON.stringify(token))
    localStorage.setItem("isLoggedIn", true)
  }).catch((e)=>{
    console.log("maybe token expired:"+JSON.stringify(e))
    localStorage.setItem("isLoggedIn", false)

  })
  console.log(">> silentAuth: return auth0.getTokenSilently()");
  callback();
  console.log("out silentAuth:");
}


// export const handleAuthentication = () => {
//   if (!isBrowser) {
//     return;
//   }

//   auth.parseHash(setSession())
// }

export const handleAuthentication = async () => {
  console.log("in handleAuthentication:");
  if (!isBrowser) {
    return;
  }
  const auth = await getClient();
  await auth.handleRedirectCallback()
  .then(result=>{
    console.log("LOGIIN RESULT:"+JSON.stringify(result))
    localStorage.setItem("isLoggedIn", true)
    navigate("/members")
  }).catch(e=>{
    navigate("/")
  })
}

// export const getProfile = () => {
//   return user
// }
export const getProfile = async () => {
  const auth = await getClient()
  const user = await auth.getUser();

  console.log("user:"+JSON.stringify(user))
  return user
}


export const getAccessToken = async () => {
    const auth = await getClient()
    const token = await auth.getTokenSilently();
    console.log(" getAccessToken:getTokenSilently: "+JSON.stringify(token))

    return token
  }
export const logout = async () => {
  localStorage.setItem("isLoggedIn", false)

  const auth = await getClient()


  auth.logout({returnTo: process.env.AUTH0_LOGOUT})
  
  // await auth.logout({
  //   returnTo: 'http://localhost:8000/'
  // }).catch(e=>{console.log("error:"+JSON.stringify(e))})
}